import React, {FunctionComponent} from 'react';
import {useQuery} from '@apollo/client';
import cx from 'classnames';
import {useObjectCategoryData} from 'client/components/CatalogWidgetStack/hooks';
import handsRuGetCategoryCoverImages, {
  HandsRuGetCategoryCoverImageData,
  HandsRuGetCategoryCoverImageVariables,
} from 'query-wrappers/HandsRuGetCategoryCoverImages';
import {CategoryImagePosition} from './constants';

import './CatalogPageCoverImage.scss';

interface OwnProps {
  position: CategoryImagePosition;
}

const CatalogPageCoverImage: FunctionComponent<OwnProps> = ({position}) => {
  const categoryData = useObjectCategoryData();
  const categorySlug = categoryData?.categorySlug as string;
  const {data} = useQuery<
    HandsRuGetCategoryCoverImageData,
    HandsRuGetCategoryCoverImageVariables
  >(handsRuGetCategoryCoverImages, {
    variables: {slug: categorySlug},
    skip: !categorySlug,
    ssr: false,
  });

  const src = (() => {
    try {
      return data?.category[position][0].src;
    } catch (e) {
      return undefined;
    }
  })();

  if (!src) {
    return null;
  }

  const cssPosition = position.toLowerCase().replace(/_/, '-');
  return (
    <img
      src={src}
      className={cx(
        'catalog-page-cover-image',
        `catalog-page-cover-image_${cssPosition}`,
        `catalog-page-cover-image_${categorySlug}_${cssPosition}`,
      )}
      alt=""
      loading="lazy"
    />
  );
};

export default CatalogPageCoverImage;
