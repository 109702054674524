import React, {FunctionComponent} from 'react';

import cx from 'classnames';
import Navbar from 'client/components/Navbar';
import Notice from 'client/components/Notice';
import Footer from 'client/components/Footer';
import StaticLayoutImage from './components/StaticLayoutImage';
import StaticLayoutBlock from './components/StaticLayoutBlock';
import StaticLayoutTitle from './components/StaticLayoutTitle';
import StaticLayoutText from './components/StaticLayoutText';
import StaticLayoutLead from './components/StaticLayoutLead';
import StaticLayoutWidget from './components/StaticLayoutWidget';
import StaticLayoutCover from './components/StaticLayoutCover';
import StaticLayoutBreadcrumbs from './components/StaticLayoutBreadcrumbs';
import StaticLayoutBleeder from './components/StaticLayoutBleeder';
import StaticLayoutList from './components/StaticLayoutList';
import StaticLayoutSheetWidget from './components/StaticLayoutSheetWidget';
import './StaticLayout.scss';

interface OwnProps {
  withFooter?: boolean;
  withNavbar?: boolean;
  extraClassName?: string;
  contentProps?: {
    [x: string]: any;
  };
}

interface StaticLayoutInterface extends FunctionComponent<OwnProps> {
  List: typeof StaticLayoutList;
  Text: typeof StaticLayoutText;
  Image: typeof StaticLayoutImage;
  Lead: typeof StaticLayoutLead;
  Block: typeof StaticLayoutBlock;
  Title: typeof StaticLayoutTitle;
  Widget: typeof StaticLayoutWidget;
  Cover: typeof StaticLayoutCover;
  Breadcrumbs: typeof StaticLayoutBreadcrumbs;
  Bleeder: typeof StaticLayoutBleeder;
  SheetWidget: typeof StaticLayoutSheetWidget;
}

const StaticLayout: StaticLayoutInterface = ({
  children,
  extraClassName,
  contentProps: {
    extraClassName: contentExtraClassName,
    ...restContentProps
  } = {},
  withFooter = true,
  withNavbar = true,
}) => {
  return (
    <div className={cx('static-layout', extraClassName)}>
      {withNavbar && <Navbar />}
      <Notice />

      <main
        {...restContentProps}
        className={cx('static-layout__wrapper', contentExtraClassName)}
      >
        {children}
      </main>

      {withFooter && <Footer themed />}
    </div>
  );
};

StaticLayout.List = StaticLayoutList;
StaticLayout.Text = StaticLayoutText;
StaticLayout.Image = StaticLayoutImage;
StaticLayout.Lead = StaticLayoutLead;
StaticLayout.Block = StaticLayoutBlock;
StaticLayout.Title = StaticLayoutTitle;
StaticLayout.Widget = StaticLayoutWidget;
StaticLayout.Cover = StaticLayoutCover;
StaticLayout.Breadcrumbs = StaticLayoutBreadcrumbs;
StaticLayout.Bleeder = StaticLayoutBleeder;
StaticLayout.SheetWidget = StaticLayoutSheetWidget;

export type {
  Areas,
  Alignment,
  BlockPadding,
  BlockProps,
} from './components/StaticLayoutBlock';
export type {WidgetPadding, WidgetProps} from './components/StaticLayoutWidget';

export default StaticLayout;
