import {ServiceSlugType, GeoUnitSlugType} from 'client/types/slug';
import {Markdown} from 'client/types/html';
import handsRuGetCatalogTexts from 'queries/handsRuGetCatalogTexts_2.graphql';
import {CatalogPageTypeEnum} from 'client/types/page-type';

interface CatalogTexts {
  algorithms: Markdown;
  classifications: Markdown;
  nuances: Markdown;
  reasons: Markdown;
}

export interface HandsRuGetCatalogTextData {
  catalog: {
    servicePage: CatalogTexts;
    objectPage: CatalogTexts;
    categoryPage: CatalogTexts;
  };
}

export interface HandsRuGetCatalogTextVariables {
  slug: ServiceSlugType;
  pageType: CatalogPageTypeEnum;
  geo?: GeoUnitSlugType;
  isServicePage: boolean;
  isObjectPage: boolean;
  isCategoryPage: boolean;
}

export default handsRuGetCatalogTexts;
