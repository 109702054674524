import React, {ReactNode, forwardRef} from 'react';
import cx from 'classnames';

import './Suggestion.css';

interface OwnProps {
  highlighted: boolean;
  children: ReactNode;
  extraClassName?: string;
}

const Suggestion = forwardRef<HTMLDivElement, OwnProps>(
  ({children, highlighted, extraClassName, ...restOfProps}, ref) => (
    <div
      ref={ref}
      className={cx('suggestion', extraClassName, {
        suggestion_highlighted: highlighted,
      })}
      {...restOfProps}
    >
      {children}
    </div>
  ),
);

export default Suggestion;
